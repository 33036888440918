<template>
  <v-lazy
    height="65px"
    :class="[{ 'pin-to-top': isSelected }]">
    <div
      :class="['orgs-list__line', { 'active': isCurrentOrg, 'open': isCurrentOrgParent }]"
      type="button"
      height="65px"
      @click="switchOrg()">
      <CyAvatar
        :item="org"
        class="mr-2"/>

      <CyTooltip
        v-if="warning"
        theme="warning"
        bottom>
        <template #activator="{ on }">
          <v-icon
            small
            color="warning"
            class="mr-2 orgs-list__warning-icon"
            v-on="on">
            warning
          </v-icon>
        </template>
        <span>{{ warning }}</span>
      </CyTooltip>

      <span class="orgs-list__name text-truncate">{{ org.name }}</span>

      <div class="orgs-list__actions ml-2">
        <v-icon
          class="orgs-list__settings-icon"
          @click.stop="goToOrgSettings">
          settings
        </v-icon>
        <v-icon
          v-if="org.has_children"
          class="orgs-list__next-icon ml-1">
          navigate_next
        </v-icon>
      </div>
    </div>
  </v-lazy>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyAvatar from '@/components/avatar.vue'

export default {
  name: 'CyOrganizationsListItem',
  components: {
    CyAvatar,
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
    org: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentScopeDepth: (state) => state.customers.currentScopeDepth,
      scopes: (state) => Object.values(state.customers.scopes),
    }),
    isCurrentOrgParent () {
      return (_.get(this.scopes[this.level], 'canonical', null) === this.org.canonical) && (this.level !== this.currentScopeDepth)
    },
    isCurrentOrg () {
      return (this.orgCanonical === this.org.canonical) && (this.level === this.currentScopeDepth)
    },
    warning () {
      const knownBlockers = _.intersection(this.org.blocked, ['require_plan', 'require_payment', 'expired_free_trial'])
      return _.isEmpty(knownBlockers)
        ? !_.isEmpty(this.org.blocked) ? this.$t('blockers.unknown') : null
        : knownBlockers.map((blocker) => this.$t(`blockers.${blocker}`)).join(', ')
    },
  },
  methods: {
    ...mapActions('organization', [
      'CHANGE_ORGANIZATION',
    ]),
    ...mapActions('customers', [
      'LOGIN_AS_CUSTOMER',
      'LOGIN_AS_PARENT',
      'LOGIN_AS_HIGHER_ORG',
      'LOGIN_AS_SIBLING',
    ]),
    async goToOrgSettings () {
      await this.switchOrg()
      this.$router.push({ name: 'orgSettings', params: { orgCanonical: this.org.canonical } })
    },
    async switchOrg () {
      const nextCanonical = this.org.canonical
      if (this.isCurrentOrg) {
        this.$emit('focusOrg')
        return
      }

      const isChildOrg = this.level > this.currentScopeDepth
      const isHigherLevelOrg = this.level < this.currentScopeDepth
      const isParentOrg = isHigherLevelOrg && _.find(this.scopes, ['canonical', nextCanonical])
      const { name } = this.org

      if (isChildOrg) await this.LOGIN_AS_CUSTOMER({ canonical: nextCanonical, name })
      else if (this.level === 0) await this.CHANGE_ORGANIZATION({ nextCanonical, name })
      else if (isHigherLevelOrg) {
        if (isParentOrg) await this.LOGIN_AS_PARENT({ canonical: nextCanonical })
        else await this.LOGIN_AS_HIGHER_ORG({ canonical: nextCanonical, scopeDepth: this.level })
      } else await this.LOGIN_AS_SIBLING({ canonical: nextCanonical, name })

      this.$emit('focusOrg')
    },
  },

  i18n: {
    messages: {
      en: {
        blockers: {
          expired_free_trial: 'Expired free trial',
          require_payment: 'Required payment',
          require_plan: 'Required plan',
          unknown: 'Unknown error',
        },
      },
      es: {
        blockers: {
          expired_free_trial: 'Prueba gratuita caducada',
          require_payment: 'Pago requerido',
          require_plan: 'Plan requerido',
          unknown: 'Error desconocido',
        },
      },
      fr: {
        blockers: {
          expired_free_trial: `Version d'essai expirée`,
          require_payment: 'Paiement requies',
          require_plan: 'Abonnement requis',
          unknown: 'Erreur inconnue',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.orgs-list {
  &__settings-icon {
    visibility: hidden;
  }

  &__next-icon {
    border-left: 1px solid map.get($grey, "light-1");
  }

  &__name {
    flex-grow: 1;
  }

  &__actions {
    display: flex;
    flex-direction: row;
  }

  &__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    border-bottom: 2px solid map.get($grey, "light-2");

    &:hover {
      background-color: map.get($grey, "light-4");

      .orgs-list__settings-icon {
        visibility: visible;
      }
    }

    &.active {
      background: linear-gradient(to right, map.get($orange, "light-4"), cy-get-color("white", "main"));
      font-weight: $font-weight-bolder;

      .orgs-list__settings-icon {
        visibility: visible;
      }
    }

    &.open {
      background: cy-get-color("primary", "light-5");
    }
  }
}

.pin-to-top {
  position: sticky;
  z-index: 1;
  top: 0;
}
</style>
